<template>
  <section class="bando-head">
    <h1>Vacations des Facteurs Services Experts</h1>
  </section>

  <div class="container">
    <section class="content">
      <!-- <h2 class="title">Rechercher un FSE</h2> -->

      <!-- SEARCH -->
      <!-- <div class="bloc-search bloc-shadow">
        <form>
          <div class="row">
            <div class="col col-12 col-md-9">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="beneficiaire"
                  placeholder="Ex : 'Albert AMONT'"
                />
              </div>
            </div>
            <div class="col col-12 col-md-3 text-right">
              <button type="submit" class="btn btn-primary">Rechercher</button>
            </div>
          </div>
        </form>
      </div> -->

      <!-- FICHE -->
      <div class="row">
        <div class="col-md-5">
          <h3>
            <img src="@/assets/img/icon-fse.png" alt="FSE" />
            <span>
              FSE
              <div
                class="fse"
                :style="'background: ' + fse.couleur + ';'"
                :data-color="fse.couleur"
              >
                {{ fse.prenom }} {{ fse.nom }}
              </div>
              <div class="coord">
                Tél : {{ fse.telephone }} <br />
                Email : <a :href="'mailto:' + fse.email">{{ fse.email }}</a>
              </div>
            </span>
          </h3>
        </div>
        <div class="col-md-7">
          <h3>
            <img src="@/assets/img/icon-zones.png" alt="Zones de vacations" />
            <span
              >Zones de vacations
              <ul>
                <!-- Class "active" sur zone sélectionnée -->
                <li
                  v-for="(zoneVacation, key) in fse.zones_vacations"
                  :key="zoneVacation"
                >
                  <a
                    href=""
                    @click.prevent="selectZv($event, zoneVacation)"
                    class="btn"
                    :class="{ active: key === 0 }"
                  >
                    {{ zoneVacation.nom }}
                  </a>
                </li>
              </ul>
            </span>
          </h3>
        </div>
      </div>

      <div class="bloc-rdv bloc-shadow">
        <div class="rdvs">
          <!-- NAV SEMAINES -->
          <div class="nav-weeks">
            <div class="week">
              <a
                href=""
                title="Semaine précédente"
                @click.prevent="semainePrecedente"
              >
                <i
                  class="fas fa-chevron-double-left"
                  v-if="!semaineEnCours"
                ></i>
              </a>
              <h3>
                Semaine du {{ debutSemaine }} au {{ finSemaine }}
                <span v-if="show && updatedV">*</span>
              </h3>
              <a
                href=""
                title="Semaine suivante"
                @click.prevent="semaineSuivante"
              >
                <i class="fas fa-chevron-double-right"></i>
              </a>
            </div>

            <button type="reset" class="btn btn-secondary mr-3">Annuler</button>
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="saveVacation"
            >
              VALIDER
            </button>
          </div>

          <div class="repeat">
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="copyPreviousWeek"
            >
              <i class="fal fa-clone"></i>
              Reprendre la configuration de la semaine précédente
            </button>
          </div>

          <!-- LUNDI -->
          <div class="bloc-day">
            <div class="custom-control custom-checkbox day">
              <input
                type="checkbox"
                class="custom-control-input"
                id="lundi"
                v-model="currentVacation.lundi.value"
              />
              <label class="custom-control-label" for="lundi">Lundi</label>
            </div>
            <table class="table-rdv" v-show="currentVacation.lundi.value">
              <thead>
                <tr>
                  <th></th>
                  <th>Durée RDV</th>
                  <th>Nombre de RDV</th>
                  <th>Premier RDV</th>
                  <th>Dernier RDV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="lundimatin"
                        v-model="currentVacation.lundi.matin.value"
                      />
                      <label class="custom-control-label" for="lundimatin">
                        Matin :
                      </label>
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.lundi.matin.duree"
                      v-if="currentVacation.lundi.matin.duree"
                      @change="change(currentVacation.lundi.matin)"
                      ><option value="15">15mn</option
                      ><option value="30">30mn</option
                      ><option value="45">45mn</option
                      ><option value="60">1h</option
                      ><option value="75">1h15mn</option
                      ><option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.lundi.matin.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.lundi.matin.nb"
                      v-if="currentVacation.lundi.matin.nb"
                      @change="change(currentVacation.lundi.matin)"
                      @keydown="change(currentVacation.lundi.matin)"
                    />
                    <span v-if="!currentVacation.lundi.matin.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.lundi.matin.debut"
                      v-if="currentVacation.lundi.matin.debut"
                      @change="change(currentVacation.lundi.matin)"
                    >
                      <option value="6:00">6:00</option>
                      <option value="6:15">6:15</option>
                      <option value="6:30">6:30</option>
                      <option value="6:45">6:45</option>
                      <option value="7:00">7:00</option>
                      <option value="7:15">7:15</option>
                      <option value="7:30">7:30</option>
                      <option value="7:45">7:45</option>
                      <option value="8:00">8:00</option>
                      <option value="8:15">8:15</option>
                      <option value="8:30">8:30</option>
                      <option value="8:45">8:45</option>
                      <option value="9:00">9:00</option>
                      <option value="9:15">9:15</option>
                      <option value="9:30">9:30</option>
                      <option value="9:45">9:45</option>
                      <option value="10:00">10:00</option>
                      <option value="10:15">10:15</option>
                      <option value="10:30">10:30</option>
                      <option value="10:45">10:45</option>
                      <option value="11:00">11:00</option>
                      <option value="11:15">11:15</option>
                      <option value="11:30">11:30</option>
                      <option value="11:45">11:45</option>
                      <option value="12:00">12:00</option>
                    </select>
                    <span v-if="!currentVacation.lundi.matin.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.lundi.matin.fin">{{
                      currentVacation.lundi.matin.fin
                    }}</span>
                    <span v-if="!currentVacation.lundi.matin.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="lundiaprem"
                        v-model="currentVacation.lundi.aprem.value"
                      />
                      <label class="custom-control-label" for="lundiaprem">
                        Après-midi :
                      </label>
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.lundi.aprem.duree"
                      v-if="currentVacation.lundi.aprem.duree"
                      @change="change(currentVacation.lundi.aprem)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.lundi.aprem.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.lundi.aprem.nb"
                      v-if="currentVacation.lundi.aprem.nb"
                      @change="change(currentVacation.lundi.aprem)"
                      @keydown="change(currentVacation.lundi.aprem)"
                    />
                    <span v-if="!currentVacation.lundi.aprem.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.lundi.aprem.debut"
                      v-if="currentVacation.lundi.aprem.debut"
                      @change="change(currentVacation.lundi.aprem)"
                    >
                      <option value="12:00">12:00</option>
                      <option value="12:15">12:15</option>
                      <option value="12:30">12:30</option>
                      <option value="12:45">12:45</option>
                      <option value="13:00">13:00</option>
                      <option value="13:15">13:15</option>
                      <option value="13:30">13:30</option>
                      <option value="13:45">13:45</option>
                      <option value="14:00">14:00</option>
                      <option value="14:15">14:15</option>
                      <option value="14:30">14:30</option>
                      <option value="14:45">14:45</option>
                      <option value="15:00">15:00</option>
                      <option value="15:15">15:15</option>
                      <option value="15:30">15:30</option>
                      <option value="15:45">15:45</option>
                      <option value="16:00">16:00</option>
                      <option value="16:15">16:15</option>
                      <option value="16:30">16:30</option>
                      <option value="16:45">16:45</option>
                      <option value="17:00">17:00</option>
                      <option value="17:15">17:15</option>
                      <option value="17:30">17:30</option>
                      <option value="17:45">17:45</option>
                      <option value="18:00">18:00</option>
                    </select>
                    <span v-if="!currentVacation.lundi.aprem.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.lundi.aprem.fin">{{
                      currentVacation.lundi.aprem.fin
                    }}</span>
                    <span v-if="!currentVacation.lundi.aprem.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- MARDI -->
          <div class="bloc-day">
            <div class="custom-control custom-checkbox day">
              <input
                type="checkbox"
                class="custom-control-input"
                id="mardi"
                v-model="currentVacation.mardi.value"
              />
              <label class="custom-control-label" for="mardi">Mardi</label>
            </div>
            <table class="table-rdv" v-show="currentVacation.mardi.value">
              <thead>
                <tr>
                  <th></th>
                  <th>Durée RDV</th>
                  <th>Nombre de RDV</th>
                  <th>Premier RDV</th>
                  <th>Dernier RDV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="mardimatin"
                        v-model="currentVacation.mardi.matin.value"
                      />
                      <label class="custom-control-label" for="mardimatin"
                        >Matin :</label
                      >
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.mardi.matin.duree"
                      v-if="currentVacation.mardi.matin.duree"
                      @change="change(currentVacation.mardi.matin)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.mardi.matin.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.mardi.matin.nb"
                      v-if="currentVacation.mardi.matin.nb"
                      @change="change(currentVacation.mardi.matin)"
                      @keydown="change(currentVacation.mardi.matin)"
                    />
                    <span v-if="!currentVacation.mardi.matin.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.mardi.matin.debut"
                      v-if="currentVacation.mardi.matin.debut"
                      @change="change(currentVacation.mardi.matin)"
                    >
                      <option value="6:00">6:00</option>
                      <option value="6:15">6:15</option>
                      <option value="6:30">6:30</option>
                      <option value="6:45">6:45</option>
                      <option value="7:00">7:00</option>
                      <option value="7:15">7:15</option>
                      <option value="7:30">7:30</option>
                      <option value="7:45">7:45</option>
                      <option value="8:00">8:00</option>
                      <option value="8:15">8:15</option>
                      <option value="8:30">8:30</option>
                      <option value="8:45">8:45</option>
                      <option value="9:00">9:00</option>
                      <option value="9:15">9:15</option>
                      <option value="9:30">9:30</option>
                      <option value="9:45">9:45</option>
                      <option value="10:00">10:00</option>
                      <option value="10:15">10:15</option>
                      <option value="10:30">10:30</option>
                      <option value="10:45">10:45</option>
                      <option value="11:00">11:00</option>
                      <option value="11:15">11:15</option>
                      <option value="11:30">11:30</option>
                      <option value="11:45">11:45</option>
                      <option value="12:00">12:00</option>
                    </select>
                    <span v-if="!currentVacation.mardi.matin.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.mardi.matin.fin">{{
                      currentVacation.mardi.matin.fin
                    }}</span>
                    <span v-if="!currentVacation.mardi.matin.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="mardiaprem"
                        v-model="currentVacation.mardi.aprem.value"
                      />
                      <label class="custom-control-label" for="mardiaprem">
                        Après-midi :
                      </label>
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.mardi.aprem.duree"
                      v-if="currentVacation.mardi.aprem.duree"
                      @change="change(currentVacation.mardi.aprem)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.mardi.aprem.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.mardi.aprem.nb"
                      v-if="currentVacation.mardi.aprem.nb"
                      @change="change(currentVacation.mardi.aprem)"
                      @keydown="change(currentVacation.mardi.aprem)"
                    />
                    <span v-if="!currentVacation.mardi.aprem.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.mardi.aprem.debut"
                      v-if="currentVacation.mardi.aprem.debut"
                      @change="change(currentVacation.mardi.aprem)"
                    >
                      <option value="12:00">12:00</option>
                      <option value="12:15">12:15</option>
                      <option value="12:30">12:30</option>
                      <option value="12:45">12:45</option>
                      <option value="13:00">13:00</option>
                      <option value="13:15">13:15</option>
                      <option value="13:30">13:30</option>
                      <option value="13:45">13:45</option>
                      <option value="14:00">14:00</option>
                      <option value="14:15">14:15</option>
                      <option value="14:30">14:30</option>
                      <option value="14:45">14:45</option>
                      <option value="15:00">15:00</option>
                      <option value="15:15">15:15</option>
                      <option value="15:30">15:30</option>
                      <option value="15:45">15:45</option>
                      <option value="16:00">16:00</option>
                      <option value="16:15">16:15</option>
                      <option value="16:30">16:30</option>
                      <option value="16:45">16:45</option>
                      <option value="17:00">17:00</option>
                      <option value="17:15">17:15</option>
                      <option value="17:30">17:30</option>
                      <option value="17:45">17:45</option>
                      <option value="18:00">18:00</option>
                    </select>
                    <span v-if="!currentVacation.mardi.aprem.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.mardi.aprem.fin">{{
                      currentVacation.mardi.aprem.fin
                    }}</span>
                    <span v-if="!currentVacation.mardi.aprem.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- MERCREDI -->
          <div class="bloc-day">
            <div class="custom-control custom-checkbox day">
              <input
                type="checkbox"
                class="custom-control-input"
                id="mercredi"
                v-model="currentVacation.mercredi.value"
              />
              <label class="custom-control-label" for="mercredi">
                Mercredi
              </label>
            </div>
            <table class="table-rdv" v-show="currentVacation.mercredi.value">
              <thead>
                <tr>
                  <th></th>
                  <th>Durée RDV</th>
                  <th>Nombre de RDV</th>
                  <th>Premier RDV</th>
                  <th>Dernier RDV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="mercrmatin"
                        v-model="currentVacation.mercredi.matin.value"
                      />
                      <label class="custom-control-label" for="mercrmatin">
                        Matin :
                      </label>
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.mercredi.matin.duree"
                      v-if="currentVacation.mercredi.matin.duree"
                      @change="change(currentVacation.mercredi.matin)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.mercredi.matin.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.mercredi.matin.nb"
                      v-if="currentVacation.mercredi.matin.nb"
                      @change="change(currentVacation.mercredi.matin)"
                      @keydown="change(currentVacation.mercredi.matin)"
                    />
                    <span v-if="!currentVacation.mercredi.matin.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.mercredi.matin.debut"
                      v-if="currentVacation.mercredi.matin.debut"
                      @change="change(currentVacation.mercredi.matin)"
                    >
                      <option value="6:00">6:00</option>
                      <option value="6:15">6:15</option>
                      <option value="6:30">6:30</option>
                      <option value="6:45">6:45</option>
                      <option value="7:00">7:00</option>
                      <option value="7:15">7:15</option>
                      <option value="7:30">7:30</option>
                      <option value="7:45">7:45</option>
                      <option value="8:00">8:00</option>
                      <option value="8:15">8:15</option>
                      <option value="8:30">8:30</option>
                      <option value="8:45">8:45</option>
                      <option value="9:00">9:00</option>
                      <option value="9:15">9:15</option>
                      <option value="9:30">9:30</option>
                      <option value="9:45">9:45</option>
                      <option value="10:00">10:00</option>
                      <option value="10:15">10:15</option>
                      <option value="10:30">10:30</option>
                      <option value="10:45">10:45</option>
                      <option value="11:00">11:00</option>
                      <option value="11:15">11:15</option>
                      <option value="11:30">11:30</option>
                      <option value="11:45">11:45</option>
                      <option value="12:00">12:00</option>
                    </select>
                    <span v-if="!currentVacation.mercredi.matin.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.mercredi.matin.fin">{{
                      currentVacation.mercredi.matin.fin
                    }}</span>
                    <span v-if="!currentVacation.mercredi.matin.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="mercraprem"
                        v-model="currentVacation.mercredi.aprem.value"
                      />
                      <label class="custom-control-label" for="mercraprem">
                        Après-midi :
                      </label>
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.mercredi.aprem.duree"
                      v-if="currentVacation.mercredi.aprem.duree"
                      @change="change(currentVacation.mercredi.aprem)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.mercredi.aprem.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.mercredi.aprem.nb"
                      v-if="currentVacation.mercredi.aprem.nb"
                      @change="change(currentVacation.mercredi.aprem)"
                      @keydown="change(currentVacation.mercredi.aprem)"
                    />
                    <span v-if="!currentVacation.mercredi.aprem.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.mercredi.aprem.debut"
                      v-if="currentVacation.mercredi.aprem.debut"
                      @change="change(currentVacation.mercredi.aprem)"
                    >
                      <option value="12:00">12:00</option>
                      <option value="12:15">12:15</option>
                      <option value="12:30">12:30</option>
                      <option value="12:45">12:45</option>
                      <option value="13:00">13:00</option>
                      <option value="13:15">13:15</option>
                      <option value="13:30">13:30</option>
                      <option value="13:45">13:45</option>
                      <option value="14:00">14:00</option>
                      <option value="14:15">14:15</option>
                      <option value="14:30">14:30</option>
                      <option value="14:45">14:45</option>
                      <option value="15:00">15:00</option>
                      <option value="15:15">15:15</option>
                      <option value="15:30">15:30</option>
                      <option value="15:45">15:45</option>
                      <option value="16:00">16:00</option>
                      <option value="16:15">16:15</option>
                      <option value="16:30">16:30</option>
                      <option value="16:45">16:45</option>
                      <option value="17:00">17:00</option>
                      <option value="17:15">17:15</option>
                      <option value="17:30">17:30</option>
                      <option value="17:45">17:45</option>
                      <option value="18:00">18:00</option>
                    </select>
                    <span v-if="!currentVacation.mercredi.aprem.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.mercredi.aprem.fin">{{
                      currentVacation.mercredi.aprem.fin
                    }}</span>
                    <span v-if="!currentVacation.mercredi.aprem.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- JEUDI -->
          <div class="bloc-day">
            <div class="custom-control custom-checkbox day">
              <input
                type="checkbox"
                class="custom-control-input"
                id="jeudi"
                v-model="currentVacation.jeudi.value"
              />
              <label class="custom-control-label" for="jeudi">Jeudi</label>
            </div>
            <table class="table-rdv" v-show="currentVacation.jeudi.value">
              <thead>
                <tr>
                  <th></th>
                  <th>Durée RDV</th>
                  <th>Nombre de RDV</th>
                  <th>Premier RDV</th>
                  <th>Dernier RDV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="jeudimatin"
                        v-model="currentVacation.jeudi.matin.value"
                      />
                      <label class="custom-control-label" for="jeudimatin">
                        Matin :
                      </label>
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.jeudi.matin.duree"
                      v-if="currentVacation.jeudi.matin.duree"
                      @change="change(currentVacation.jeudi.matin)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.jeudi.matin.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.jeudi.matin.nb"
                      v-if="currentVacation.jeudi.matin.nb"
                      @change="change(currentVacation.jeudi.matin)"
                      @keydown="change(currentVacation.jeudi.matin)"
                    />
                    <span v-if="!currentVacation.jeudi.matin.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.jeudi.matin.debut"
                      v-if="currentVacation.jeudi.matin.debut"
                      @change="change(currentVacation.jeudi.matin)"
                    >
                      <option value="6:00">6:00</option>
                      <option value="6:15">6:15</option>
                      <option value="6:30">6:30</option>
                      <option value="6:45">6:45</option>
                      <option value="7:00">7:00</option>
                      <option value="7:15">7:15</option>
                      <option value="7:30">7:30</option>
                      <option value="7:45">7:45</option>
                      <option value="8:00">8:00</option>
                      <option value="8:15">8:15</option>
                      <option value="8:30">8:30</option>
                      <option value="8:45">8:45</option>
                      <option value="9:00">9:00</option>
                      <option value="9:15">9:15</option>
                      <option value="9:30">9:30</option>
                      <option value="9:45">9:45</option>
                      <option value="10:00">10:00</option>
                      <option value="10:15">10:15</option>
                      <option value="10:30">10:30</option>
                      <option value="10:45">10:45</option>
                      <option value="11:00">11:00</option>
                      <option value="11:15">11:15</option>
                      <option value="11:30">11:30</option>
                      <option value="11:45">11:45</option>
                      <option value="12:00">12:00</option>
                    </select>
                    <span v-if="!currentVacation.jeudi.matin.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.jeudi.matin.fin">{{
                      currentVacation.jeudi.matin.fin
                    }}</span>
                    <span v-if="!currentVacation.jeudi.matin.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="jeudiaprem"
                        v-model="currentVacation.jeudi.aprem.value"
                      />
                      <label class="custom-control-label" for="jeudiaprem"
                        >Après-midi :</label
                      >
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.jeudi.aprem.duree"
                      v-if="currentVacation.jeudi.aprem.duree"
                      @change="change(currentVacation.jeudi.aprem)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.jeudi.aprem.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.jeudi.aprem.nb"
                      v-if="currentVacation.jeudi.aprem.nb"
                      @change="change(currentVacation.jeudi.aprem)"
                      @keydown="change(currentVacation.jeudi.aprem)"
                    />
                    <span v-if="!currentVacation.jeudi.aprem.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.jeudi.aprem.debut"
                      v-if="currentVacation.jeudi.aprem.debut"
                      @change="change(currentVacation.jeudi.aprem)"
                    >
                      <option value="12:00">12:00</option>
                      <option value="12:15">12:15</option>
                      <option value="12:30">12:30</option>
                      <option value="12:45">12:45</option>
                      <option value="13:00">13:00</option>
                      <option value="13:15">13:15</option>
                      <option value="13:30">13:30</option>
                      <option value="13:45">13:45</option>
                      <option value="14:00">14:00</option>
                      <option value="14:15">14:15</option>
                      <option value="14:30">14:30</option>
                      <option value="14:45">14:45</option>
                      <option value="15:00">15:00</option>
                      <option value="15:15">15:15</option>
                      <option value="15:30">15:30</option>
                      <option value="15:45">15:45</option>
                      <option value="16:00">16:00</option>
                      <option value="16:15">16:15</option>
                      <option value="16:30">16:30</option>
                      <option value="16:45">16:45</option>
                      <option value="17:00">17:00</option>
                      <option value="17:15">17:15</option>
                      <option value="17:30">17:30</option>
                      <option value="17:45">17:45</option>
                      <option value="18:00">18:00</option>
                    </select>
                    <span v-if="!currentVacation.jeudi.aprem.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.jeudi.aprem.fin">{{
                      currentVacation.jeudi.aprem.fin
                    }}</span>
                    <span v-if="!currentVacation.jeudi.aprem.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- VENDREDI -->
          <div class="bloc-day">
            <div class="custom-control custom-checkbox day">
              <input
                type="checkbox"
                class="custom-control-input"
                id="vendredi"
                v-model="currentVacation.vendredi.value"
              />
              <label class="custom-control-label" for="vendredi">
                Vendredi
              </label>
            </div>
            <table class="table-rdv" v-show="currentVacation.vendredi.value">
              <thead>
                <tr>
                  <th></th>
                  <th>Durée RDV</th>
                  <th>Nombre de RDV</th>
                  <th>Premier RDV</th>
                  <th>Dernier RDV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="vendrmatin"
                        v-model="currentVacation.vendredi.matin.value"
                      />
                      <label class="custom-control-label" for="vendrmatin">
                        Matin :
                      </label>
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.vendredi.matin.duree"
                      v-if="currentVacation.vendredi.matin.duree"
                      @change="change(currentVacation.vendredi.matin)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.vendredi.matin.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.vendredi.matin.nb"
                      v-if="currentVacation.vendredi.matin.nb"
                      @change="change(currentVacation.vendredi.matin)"
                      @keydown="change(currentVacation.vendredi.matin)"
                    />
                    <span v-if="!currentVacation.vendredi.matin.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.vendredi.matin.debut"
                      v-if="currentVacation.vendredi.matin.debut"
                      @change="change(currentVacation.vendredi.matin)"
                    >
                      <option value="6:00">6:00</option>
                      <option value="6:15">6:15</option>
                      <option value="6:30">6:30</option>
                      <option value="6:45">6:45</option>
                      <option value="7:00">7:00</option>
                      <option value="7:15">7:15</option>
                      <option value="7:30">7:30</option>
                      <option value="7:45">7:45</option>
                      <option value="8:00">8:00</option>
                      <option value="8:15">8:15</option>
                      <option value="8:30">8:30</option>
                      <option value="8:45">8:45</option>
                      <option value="9:00">9:00</option>
                      <option value="9:15">9:15</option>
                      <option value="9:30">9:30</option>
                      <option value="9:45">9:45</option>
                      <option value="10:00">10:00</option>
                      <option value="10:15">10:15</option>
                      <option value="10:30">10:30</option>
                      <option value="10:45">10:45</option>
                      <option value="11:00">11:00</option>
                      <option value="11:15">11:15</option>
                      <option value="11:30">11:30</option>
                      <option value="11:45">11:45</option>
                      <option value="12:00">12:00</option>
                    </select>
                    <span v-if="!currentVacation.vendredi.matin.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.vendredi.matin.fin">{{
                      currentVacation.vendredi.matin.fin
                    }}</span>
                    <span v-if="!currentVacation.vendredi.matin.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="vendraprem"
                        v-model="currentVacation.vendredi.aprem.value"
                      />
                      <label class="custom-control-label" for="vendraprem">
                        Après-midi :
                      </label>
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.vendredi.aprem.duree"
                      v-if="currentVacation.vendredi.aprem.duree"
                      @change="change(currentVacation.vendredi.aprem)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.vendredi.aprem.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.vendredi.aprem.nb"
                      v-if="currentVacation.vendredi.aprem.nb"
                      @change="change(currentVacation.vendredi.aprem)"
                      @keydown="change(currentVacation.vendredi.aprem)"
                    />
                    <span v-if="!currentVacation.vendredi.aprem.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.vendredi.aprem.debut"
                      v-if="currentVacation.vendredi.aprem.debut"
                      @change="change(currentVacation.vendredi.aprem)"
                    >
                      <option value="12:00">12:00</option>
                      <option value="12:15">12:15</option>
                      <option value="12:30">12:30</option>
                      <option value="12:45">12:45</option>
                      <option value="13:00">13:00</option>
                      <option value="13:15">13:15</option>
                      <option value="13:30">13:30</option>
                      <option value="13:45">13:45</option>
                      <option value="14:00">14:00</option>
                      <option value="14:15">14:15</option>
                      <option value="14:30">14:30</option>
                      <option value="14:45">14:45</option>
                      <option value="15:00">15:00</option>
                      <option value="15:15">15:15</option>
                      <option value="15:30">15:30</option>
                      <option value="15:45">15:45</option>
                      <option value="16:00">16:00</option>
                      <option value="16:15">16:15</option>
                      <option value="16:30">16:30</option>
                      <option value="16:45">16:45</option>
                      <option value="17:00">17:00</option>
                      <option value="17:15">17:15</option>
                      <option value="17:30">17:30</option>
                      <option value="17:45">17:45</option>
                      <option value="18:00">18:00</option>
                    </select>
                    <span v-if="!currentVacation.vendredi.aprem.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.vendredi.aprem.fin">{{
                      currentVacation.vendredi.aprem.fin
                    }}</span>
                    <span v-if="!currentVacation.vendredi.aprem.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- SAMEDI -->
          <div class="bloc-day">
            <div class="custom-control custom-checkbox day">
              <input
                type="checkbox"
                class="custom-control-input"
                id="samedi"
                v-model="currentVacation.samedi.value"
              />
              <label class="custom-control-label" for="samedi">Samedi</label>
            </div>
            <table class="table-rdv" v-show="currentVacation.samedi.value">
              <thead>
                <tr>
                  <th></th>
                  <th>Durée RDV</th>
                  <th>Nombre de RDV</th>
                  <th>Premier RDV</th>
                  <th>Dernier RDV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="samedimatin"
                        v-model="currentVacation.samedi.matin.value"
                      />
                      <label class="custom-control-label" for="samedimatin">
                        Matin :
                      </label>
                    </div>
                  </th>
                  <td data-label="Durée RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.samedi.matin.duree"
                      v-if="currentVacation.samedi.matin.duree"
                      @change="change(currentVacation.samedi.matin)"
                    >
                      <option value="15">15mn</option>
                      <option value="30">30mn</option>
                      <option value="45">45mn</option>
                      <option value="60">1h</option>
                      <option value="75">1h15mn</option>
                      <option value="90">1h30mn</option>
                    </select>
                    <span v-if="!currentVacation.samedi.matin.duree"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Nombre de RDV">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="10"
                      v-model.number="currentVacation.samedi.matin.nb"
                      v-if="currentVacation.samedi.matin.nb"
                      @change="change(currentVacation.samedi.matin)"
                      @keydown="change(currentVacation.samedi.matin)"
                    />
                    <span v-if="!currentVacation.samedi.matin.nb"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Premier RDV">
                    <select
                      class="custom-select"
                      v-model="currentVacation.samedi.matin.debut"
                      v-if="currentVacation.samedi.matin.debut"
                      @change="change(currentVacation.samedi.matin)"
                    >
                      <option value="6:00">6:00</option>
                      <option value="6:15">6:15</option>
                      <option value="6:30">6:30</option>
                      <option value="6:45">6:45</option>
                      <option value="7:00">7:00</option>
                      <option value="7:15">7:15</option>
                      <option value="7:30">7:30</option>
                      <option value="7:45">7:45</option>
                      <option value="8:00">8:00</option>
                      <option value="8:15">8:15</option>
                      <option value="8:30">8:30</option>
                      <option value="8:45">8:45</option>
                      <option value="9:00">9:00</option>
                      <option value="9:15">9:15</option>
                      <option value="9:30">9:30</option>
                      <option value="9:45">9:45</option>
                      <option value="10:00">10:00</option>
                      <option value="10:15">10:15</option>
                      <option value="10:30">10:30</option>
                      <option value="10:45">10:45</option>
                      <option value="11:00">11:00</option>
                      <option value="11:15">11:15</option>
                      <option value="11:30">11:30</option>
                      <option value="11:45">11:45</option>
                      <option value="12:00">12:00</option>
                    </select>
                    <span v-if="!currentVacation.samedi.matin.debut"
                      >- - - - -</span
                    >
                  </td>
                  <td data-label="Dernier RDV">
                    <span v-if="currentVacation.samedi.matin.fin">{{
                      currentVacation.samedi.matin.fin
                    }}</span>
                    <span v-if="!currentVacation.samedi.matin.fin"
                      >- - - - -</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="repeat">
            <!-- Répéter jusqu'à : <input type="date" class="form-control" value="2021-05-21"> -->
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="copyPreviousWeek"
            >
              <i class="fal fa-clone"></i> Reprendre la configuration de la
              semaine précédente
            </button>
          </div>

          <div class="text-center">
            <button type="reset" class="btn btn-secondary mr-3">Annuler</button>
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="saveVacation"
            >
              VALIDER
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import axios from "axios";
import { DateTime, Settings } from "luxon";

Settings.defaultLocale = "fr";

export default {
  name: "FicheFSE",
  components: {},
  data() {
    return {
      updated: false,
      initialVacation: {},
      currentVacation: {},
      defaultVacation: {
        lundi: {
          value: false,
          matin: {
            value: false
          },
          aprem: {
            value: false
          }
        },
        mardi: {
          value: false,
          matin: {
            value: false
          },
          aprem: {
            value: false
          }
        },
        mercredi: {
          value: false,
          matin: {
            value: false
          },
          aprem: {
            value: false
          }
        },
        jeudi: {
          value: false,
          matin: {
            value: false
          },
          aprem: {
            value: false
          }
        },
        vendredi: {
          value: false,
          matin: {
            value: false
          },
          aprem: {
            value: false
          }
        },
        samedi: {
          value: false,
          matin: {
            value: false
          },
          aprem: {
            value: false
          }
        }
      },
      dateDebutSemaine: DateTime.now(),
      vacations: [],
      activeZone: {},
      fse: {},
      show: false,
      error: false,
      loading: false
    };
  },
  computed: {
    updatedV() {
      return (
        JSON.stringify(this.initialVacation) !==
        JSON.stringify(this.currentVacation)
      );
    },
    semaineEnCours() {
      return this.dateDebutSemaine.equals(DateTime.now().startOf("week"));
    },
    dateFinSemaine() {
      return this.dateDebutSemaine.endOf("week");
    },
    debutSemaine() {
      let format = { month: "long", day: "numeric" };
      if (this.dateDebutSemaine.month === this.dateFinSemaine.month) {
        format = { day: "numeric" };
      }
      if (this.dateDebutSemaine.year !== this.dateFinSemaine.year) {
        format = { year: "numeric", month: "long", day: "numeric" };
      }
      return this.dateDebutSemaine
        .toLocaleString(format)
        .replace(/^1$/, "1er")
        .replace(/^1 /, "1er ");
    },
    finSemaine() {
      return this.dateFinSemaine
        .toLocaleString(DateTime.DATE_FULL)
        .replace(/^1$/, "1er")
        .replace(/^1 /, "1er ");
    }
  },
  created() {
    this.dateDebutSemaine = DateTime.now().startOf("week");
    this.currentVacation = JSON.parse(JSON.stringify(this.defaultVacation));
    const route = useRoute();
    axios
      .get(process.env.VUE_APP_API + "/fse/" + route.params.id)
      .then(resp => {
        this.fse = resp.data;
        this.activeZone = this.fse.zones_vacations[0];
        if (this.fse.vacations && this.fse.vacations.length) {
          this.vacations = this.fse.vacations.map(zv => {
            const v = {};

            v.id = zv.id;
            v.details = zv.details;
            v.zone = {
              id: zv.zone.id
            };
            v.fse = {
              id: this.fse.id
            };

            v.date_start =
              zv.date_start && zv.date_start.length
                ? zv.date_start
                : this.dateDebutSemaine;

            v.date_end =
              zv.date_end && zv.date_end.length
                ? zv.date_end
                : this.dateFinSemaine;

            if (
              this.dateDebutSemaine.equals(
                DateTime.fromISO(zv.date_start).startOf("week")
              ) &&
              zv.zone.id == this.activeZone.id
            ) {
              this.currentVacation = JSON.parse(JSON.stringify(zv.details));
              if (zv.id) {
                this.currentVacation.id = zv.id;
              }
            }

            return v;
          });
        }
        this.initialVacation = JSON.parse(JSON.stringify(this.currentVacation));
        this.show = true;
      })
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  methods: {
    copyPreviousWeek() {
      this.$swal
        .fire({
          icon: "question",
          titleText:
            "Souhaitez-vous copier les vacations de la semaine précédente ?",
          showDenyButton: true,
          confirmButtonText: `Oui`,
          denyButtonText: `Non`
        })
        .then(result => {
          if (result.isConfirmed) {
            const v = {};

            if (this.currentVacation.id) {
              v.id = this.currentVacation.id;
            }
            const cv = this.vacations.filter(v => {
              return (
                v.zone.id == this.activeZone.id &&
                DateTime.fromISO(v.date_start).toISODate() ==
                  this.dateDebutSemaine
                    .minus({
                      weeks: 1
                    })
                    .toISODate()
              );
            });
            if (cv && cv[0]) {
              this.vacations = this.vacations.filter(v => {
                return (
                  v.zone.id != this.activeZone.id ||
                  (v.zone.id == this.activeZone.id &&
                    DateTime.fromISO(v.date_start).toISODate() !=
                      this.dateDebutSemaine.toISODate())
                );
              });
              this.currentVacation = JSON.parse(JSON.stringify(cv[0].details));
            } else {
              this.currentVacation = JSON.parse(
                JSON.stringify(this.defaultVacation)
              );
            }
            this.initialVacation = JSON.parse(
              JSON.stringify(this.currentVacation)
            );

            v.details = this.currentVacation;
            v.zone = {
              id: this.activeZone.id
            };
            v.fse = {
              id: this.fse.id
            };

            v.date_start = this.dateDebutSemaine;
            v.date_end = this.dateFinSemaine;

            this.vacations.push(v);
            axios
              .post(process.env.VUE_APP_API + "/vacation/save", v)
              .then(() => {
                this.$swal.fire(
                  "Les vacations ont bien été enregistrées.",
                  "",
                  "success"
                );
              })
              .catch(() => {
                this.$swal.fire("Une erreur s'est produite.", "", "error");
              });
          } else if (result.isDenied) {
            // this.$swal.fire("Les modifications ont été annulées.", "", "info");
          }
        });
    },
    isVacationValid() {
      let isValid = true;
      let allFalse = true;
      let msg = [];
      for (const v in this.currentVacation) {
        if (v !== "id" && this.currentVacation[v].value === true) {
          console.log(parseInt(this.currentVacation[v].nb));
          if (
            this.currentVacation[v].matin?.value === false &&
            this.currentVacation[v].aprem?.value === false
          ) {
            isValid = false;
            msg.push("Demi-journée manquante pour " + v);
          } else if (
            parseInt(this.currentVacation[v].matin?.nb) === 0 ||
            parseInt(this.currentVacation[v].aprem?.nb) === 0
          ) {
            isValid = false;
            msg.push("Nombre de rendez-vous invalide pour " + v);
          }
          allFalse = false;
        }
      }
      if (allFalse) {
        isValid = false;
        msg.push("Aucun jour sélectionné");
      }
      if (!isValid) {
        this.$swal.fire("Vacation invalide.", msg.join("\n"), "error");
      }
      return isValid;
    },
    saveUpdated(callback) {
      this.$swal
        .fire({
          icon: "question",
          titleText: "Souhaitez-vous enregistrer vos modifications ?",
          html:
            this.fse.prenom +
            " " +
            this.fse.nom +
            "<br />" +
            this.activeZone.nom +
            "<br />" +
            "Semaine du " +
            this.debutSemaine +
            " au " +
            this.finSemaine,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Oui`,
          denyButtonText: `Non`,
          cancelButtonText: `Annuler`
        })
        .then(result => {
          if (result.isConfirmed && this.isVacationValid()) {
            const v = {};

            if (this.currentVacation.id) {
              v.id = this.currentVacation.id;
            }

            v.details = this.currentVacation;
            v.zone = {
              id: this.activeZone.id
            };
            v.fse = {
              id: this.fse.id
            };

            v.date_start = this.dateDebutSemaine;
            v.date_end = this.dateFinSemaine;

            this.vacations.push(v);
            axios
              .post(process.env.VUE_APP_API + "/vacation/save", v)
              .then(() => {
                this.$swal
                  .fire(
                    "Les vacations ont bien été enregistrées.",
                    "",
                    "success"
                  )
                  .then(callback);
              })
              .catch(() => {
                this.$swal
                  .fire("Une erreur s'est produite.", "", "error")
                  .then(callback);
              });
          } else if (result.isDenied) {
            this.$swal
              .fire("Les modifications ont été annulées.", "", "info")
              .then(callback);
          }
        });
    },
    semainePrecedente() {
      if (this.updatedV) {
        this.saveUpdated(() => {
          this.dateDebutSemaine = this.dateDebutSemaine.minus({
            weeks: 1
          });
          const cv = this.vacations.filter(v => {
            return (
              v.zone.id == this.activeZone.id &&
              DateTime.fromISO(v.date_start).toISODate() ==
                this.dateDebutSemaine.toISODate()
            );
          });
          if (cv && cv[0]) {
            this.currentVacation = JSON.parse(JSON.stringify(cv[0].details));
            if (cv[0].id) {
              this.currentVacation.id = cv[0].id;
            }
          } else {
            this.currentVacation = JSON.parse(
              JSON.stringify(this.defaultVacation)
            );
          }
          this.initialVacation = JSON.parse(
            JSON.stringify(this.currentVacation)
          );
          this.updated = false;
        });
      } else {
        this.dateDebutSemaine = this.dateDebutSemaine.minus({
          weeks: 1
        });
        this.updated = false;
        const cv = this.vacations.filter(v => {
          return (
            v.zone.id == this.activeZone.id &&
            DateTime.fromISO(v.date_start).toISODate() ==
              this.dateDebutSemaine.toISODate()
          );
        });
        if (cv && cv[0]) {
          this.currentVacation = JSON.parse(JSON.stringify(cv[0].details));
          if (cv[0].id) {
            this.currentVacation.id = cv[0].id;
          }
        } else {
          this.currentVacation = JSON.parse(
            JSON.stringify(this.defaultVacation)
          );
        }
        this.initialVacation = JSON.parse(JSON.stringify(this.currentVacation));
      }
    },
    semaineSuivante() {
      if (this.updatedV) {
        this.saveUpdated(() => {
          this.dateDebutSemaine = this.dateDebutSemaine.plus({
            weeks: 1
          });
          const cv = this.vacations.filter(v => {
            return (
              v.zone.id == this.activeZone.id &&
              DateTime.fromISO(v.date_start).toISODate() ==
                this.dateDebutSemaine.toISODate()
            );
          });
          if (cv && cv[0]) {
            this.currentVacation = JSON.parse(JSON.stringify(cv[0].details));
            if (cv[0].id) {
              this.currentVacation.id = cv[0].id;
            }
          } else {
            this.currentVacation = JSON.parse(
              JSON.stringify(this.defaultVacation)
            );
          }
          this.initialVacation = JSON.parse(
            JSON.stringify(this.currentVacation)
          );
          this.updated = false;
        });
      } else {
        this.dateDebutSemaine = this.dateDebutSemaine.plus({
          weeks: 1
        });
        this.updated = false;
        const cv = this.vacations.filter(v => {
          return (
            v.zone.id == this.activeZone.id &&
            DateTime.fromISO(v.date_start).toISODate() ==
              this.dateDebutSemaine.toISODate()
          );
        });
        if (cv && cv[0]) {
          this.currentVacation = JSON.parse(JSON.stringify(cv[0].details));
          if (cv[0].id) {
            this.currentVacation.id = cv[0].id;
          }
        } else {
          this.currentVacation = JSON.parse(
            JSON.stringify(this.defaultVacation)
          );
        }
        this.initialVacation = JSON.parse(JSON.stringify(this.currentVacation));
      }
    },
    selectZv(e, zoneVacation) {
      if (zoneVacation.id != this.activeZone.id) {
        if (this.updatedV) {
          this.saveUpdated(() => {
            document.querySelectorAll("a.btn").forEach(node => {
              node.classList.remove("active");
            });
            e.srcElement.classList.add("active");
            this.activeZone = zoneVacation;
            const cv = this.vacations.filter(v => {
              return (
                v.zone.id == this.activeZone.id &&
                DateTime.fromISO(v.date_start).toISODate() ==
                  this.dateDebutSemaine.toISODate()
              );
            });
            if (cv && cv[0]) {
              this.currentVacation = JSON.parse(JSON.stringify(cv[0].details));
              if (cv[0].id) {
                this.currentVacation.id = cv[0].id;
              }
            } else {
              this.currentVacation = JSON.parse(
                JSON.stringify(this.defaultVacation)
              );
            }
            this.initialVacation = JSON.parse(
              JSON.stringify(this.currentVacation)
            );
            this.updated = false;
          });
        } else {
          document.querySelectorAll("a.btn").forEach(node => {
            node.classList.remove("active");
          });
          e.srcElement.classList.add("active");
          this.activeZone = zoneVacation;
          const cv = this.vacations.filter(v => {
            return (
              v.zone.id == this.activeZone.id &&
              DateTime.fromISO(v.date_start).toISODate() ==
                this.dateDebutSemaine.toISODate()
            );
          });
          if (cv && cv[0]) {
            this.currentVacation = JSON.parse(JSON.stringify(cv[0].details));
            if (cv[0].id) {
              this.currentVacation.id = cv[0].id;
            }
          } else {
            this.currentVacation = JSON.parse(
              JSON.stringify(this.defaultVacation)
            );
          }
          this.initialVacation = JSON.parse(
            JSON.stringify(this.currentVacation)
          );
        }
      }
    },
    change(target) {
      if (parseInt(target.nb)) {
        this.updated = true;
        let nbMs = 60000;
        if (target.duree.match(/h$/g)) {
          nbMs = 3600000;
        }

        const debut = new Date("1970-01-01 " + target.debut);
        const last = new Date(
          debut.getTime() +
            parseInt(target.duree) * (target.nb > 0 ? target.nb - 1 : 0) * nbMs
        );

        const options = { hour: "2-digit", minute: "2-digit" };
        // if (last.toLocaleTimeString("fr-FR", options) > "13:00") {
        //   this.$swal.fire("Une erreur s'est produite.", "", "error");
        // } else {
        target.fin = last.toLocaleTimeString("fr-FR", options);
        // }
      } else {
        delete target.debut;
        delete target.fin;
      }
    },
    saveVacation() {
      if (this.isVacationValid()) {
        const v = {};

        if (this.currentVacation.id) {
          v.id = this.currentVacation.id;
        }

        v.details = this.currentVacation;
        v.zone = {
          id: this.activeZone.id
        };
        v.fse = {
          id: this.fse.id
        };

        v.date_start = this.dateDebutSemaine;
        v.date_end = this.dateFinSemaine;

        this.vacations.push(v);
        axios
          .post(process.env.VUE_APP_API + "/vacation/save", v)
          .then(() => {
            this.initialVacation = JSON.parse(
              JSON.stringify(this.currentVacation)
            );
            this.$swal.fire(
              "Les vacations ont bien été enregistrées.",
              "",
              "success"
            );
          })
          .catch(() => {
            this.$swal.fire("Une erreur s'est produite.", "", "error");
          });
      }
    },
    showAlert(txt) {
      this.$swal
        .fire(txt)
        .then(() => this.$router.push("/vacations-etablissement"));
    }
  },
  watch: {
    currentVacation: {
      handler(newValue) {
        for (const v in newValue) {
          if (v !== "id" && newValue[v].value === false) {
            newValue[v].matin.value = false;
            newValue[v].aprem.value = false;
          }
          if (v !== "id" && newValue[v].matin.value && !newValue[v].matin.nb) {
            newValue[v].matin.duree = "45";
            newValue[v].matin.nb = "0";
          } else if (
            v !== "id" &&
            parseInt(newValue[v].matin.nb) &&
            !newValue[v].matin.debut
          ) {
            newValue[v].matin.debut = "8:30";
            this.change(newValue[v].matin);
          } else if (v !== "id" && newValue[v].matin.value === false) {
            delete newValue[v].matin.debut;
            delete newValue[v].matin.nb;
            delete newValue[v].matin.duree;
            delete newValue[v].matin.fin;
          }
          if (v !== "id" && newValue[v].aprem.value && !newValue[v].aprem.nb) {
            newValue[v].aprem.duree = "45";
            newValue[v].aprem.nb = "0";
          } else if (
            v !== "id" &&
            parseInt(newValue[v].aprem.nb) &&
            !newValue[v].aprem.debut
          ) {
            newValue[v].aprem.debut = "13:30";
            this.change(newValue[v].aprem);
          } else if (v !== "id" && newValue[v].aprem.value === false) {
            delete newValue[v].aprem.debut;
            delete newValue[v].aprem.nb;
            delete newValue[v].aprem.duree;
            delete newValue[v].aprem.fin;
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  .bloc-search {
    margin-bottom: 5rem;
  }

  h3 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;

    @media screen and (min-width: 992px) {
      align-items: center;
    }

    .fse {
      font-size: 1.4rem;
      margin: 0.5rem 0;
      padding: 0.75rem 2rem;
    }

    .coord {
      font-size: 1.3rem;
      font-weight: 400;
    }

    img {
      margin-right: 1.5rem;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0.5rem 0 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0 1rem 0.5rem 0;
      }

      .btn {
        background: var(--grey);
        color: white;
        font-size: 1.4rem;
        padding: 0.75rem 3rem;

        &:hover,
        &.active {
          background: var(--navy);
        }
      }
    }
  }

  .bloc-rdv {
    background: url("../assets/img/icon-calendar.png") no-repeat 2rem 2rem;
    border-radius: 1rem;
    margin-bottom: 4.5rem;
    padding: 10rem 2rem 2rem;

    @media screen and (min-width: 992px) {
      padding: 2.5rem 5rem 2.5rem 14rem;
    }

    .nav-weeks {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      text-align: center;
      padding-bottom: 3rem;

      @media screen and (min-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0 4rem;
      }

      .week {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        padding-bottom: 2rem;

        @media screen and (min-width: 992px) {
          padding: 0 4rem 0;
        }
      }

      h3 {
        margin: 0;

        @media screen and (min-width: 992px) {
          font-size: 2.2rem;
        }
      }

      a {
        color: black;
        font-size: 2rem;

        @media screen and (min-width: 992px) {
          font-size: 2.4rem;
        }

        &:hover {
          color: var(--yellow);
        }
      }
    }

    .rdvs {
      font-size: 1.4rem;

      @media screen and (min-width: 992px) {
        font-size: 1.6rem;
      }

      .bloc-day {
        border-bottom: 1px solid var(--lightgrey);
        margin-bottom: 2rem;

        @media screen and (min-width: 992px) {
          display: flex;
        }

        .day {
          font-family: var(--font-title);
          font-size: 1.8rem;
          font-weight: 700;
          list-style: none;
          color: var(--darkgrey);
          text-align: center;

          @media screen and (min-width: 992px) {
            margin: 1.2rem 0 0;
            text-align: left;
            min-width: 15%;
          }
        }
      }

      .table-rdv {
        border-collapse: separate;
        border-spacing: 0 1rem;
        margin-bottom: 3rem;
        text-align: center;

        @media screen and (min-width: 992px) {
          max-width: 85%;
        }

        th {
          padding: 0 1rem;
        }

        tbody {
          th {
            text-align: left;
          }
        }

        td {
          background: #c9c9c9;
          font-size: 1.4rem;
          font-weight: 600;
          padding: 1rem 0.5rem;

          @media screen and (min-width: 992px) {
            padding: 1rem;
            width: 18%;
          }

          &:nth-child(2) {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:nth-child(5) {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          .form-control,
          .custom-select {
            display: inline;
            font-weight: 600;
            height: 3.5rem;
            width: auto;
          }

          .form-control {
            max-width: 10rem;
            text-align: center;
          }

          span {
            background: #e8e8e8;
            border-radius: 0.5rem;
            display: inline-block;
            padding: 0.5rem 1rem;
            height: 3.5rem;
            line-height: 2.5rem;
          }
        }

        @media screen and (max-width: 991px) {
          width: 100%;

          thead {
            display: none;
          }

          tr {
            display: block;
            margin-bottom: 2rem;
          }

          td {
            border-radius: 0.5rem;
            display: block;
            margin-bottom: 1rem;
            padding: 1rem;
            text-align: right;

            &:before {
              content: attr(data-label);
              float: left;
              font-weight: bold;
              line-height: 3.5rem;
            }

            .form-control,
            .custom-select,
            span {
              width: 10rem;
            }
          }
        }
      }

      .repeat {
        text-align: center;
        font-weight: 700;
        margin-bottom: 2rem;

        @media screen and (min-width: 992px) {
          font-size: 1.8rem;
        }

        i {
          font-size: 2rem;
          margin-right: 1rem;
        }
      }
    }
  }
}
</style>
